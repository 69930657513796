var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-menu',{staticClass:"pl-2 pr-2",attrs:{"default-active":_vm.active,"mode":"horizontal","background-color":"#f3f3f3","active-text-color":_vm.themeColor}},[_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"2"}},[_c('router-link',{staticClass:"linkInTab",attrs:{"to":{
          name: 'SATResults',
          query: {
            type: 'drill'
          }
        }}},[_c('b',[_vm._v(_vm._s(_vm.$t("sat.SAT_Results")))])])],1),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"3"}},[_c('router-link',{staticClass:"linkInTab",attrs:{"to":{
          name: 'SATPerformance'
        }}},[_c('b',[_vm._v(_vm._s(_vm.$t("pageTitle.Performance")))])])],1),(false)?[_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"4"}},[_c('router-link',{staticClass:"linkInTab",attrs:{"to":{
            name: 'Balance'
          }}},[_c('b',[_vm._v(_vm._s(_vm.$t("pageTitle.Balance")))])])],1),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"5"}},[_c('router-link',{staticClass:"linkInTab",attrs:{"to":{
            name: 'MyClasses'
          }}},[_c('b',[_vm._v(_vm._s(_vm.$t("TeacherCourses.My Classes")))])])],1),(_vm.isTeacher)?_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"6"}},[_c('router-link',{staticClass:"linkInTab",attrs:{"to":{
            name: 'TeacherClasses'
          }}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("TeacherCourses.Classes I Created"))+" ")])])],1):_vm._e(),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"7"}},[_c('router-link',{staticClass:"linkInTab",attrs:{"to":{
            name: 'VideoClasses'
          }}},[_c('b',[_vm._v(" 視頻課程 ")])])],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }