<template>
  <div class="box">
    <SATMenu active="3" />
    <div class="container">
      <h1 class="sat_left">
        <p>{{ $t("pageTitle.Performance") }}</p>
      </h1>
    </div>
    <div v-if="tag_analysis && tag_analysis.length > 0">
      <div
        v-for="(tag_analysis, key, index) in tag_analysis_arr"
        :key="index"
        class="pl-4 pr-4"
      >
        <hr />
        <Performance
          v-if="tag_analysis && tag_analysis.length > 0"
          section="Reading"
          :tagType="key"
          :tags="tag_analysis"
          :activeName="activeName"
        ></Performance>
      </div>
    </div>
    <div v-else class="container">
      <hr />
      <el-empty description="No Data"></el-empty>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import SATMenu from "@/views/satList/components/SATMenu.vue";
import SAT from "@/apis/sat";
import Performance from "@/views/performance/performance/Index.vue";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.Performance")} - ${this.CompanyName}`
    };
  },

  components: {
    SATMenu,
    Performance
  },

  mixins: [],

  props: ["activeName"],
  data() {
    return {
      tag_analysis: null
    };
  },
  computed: {
    ...mapState("user", ["lang", "profile"]),
    tag_analysis_arr() {
      let res = null;
      if (this.tag_analysis) {
        res = _.groupBy(this.tag_analysis, "tag_type");
      }
      return res;
    }
  },
  watch: {
    profile(profile) {
      if (profile.id) {
        this.getUserTagAnalysis();
      }
    }
  },

  mounted() {
    if (this.profile.id) {
      this.getUserTagAnalysis();
    }
  },

  methods: {
    async getUserTagAnalysis() {
      const res = await SAT.getUserTagAnalysis(this.profile.id);
      this.tag_analysis = res ? res : null;
    }
  }
};
</script>

<style scoped>
.box .container {
  padding-top: 0;
}
.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}
.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}
@media screen and (max-width: 768px) {
  .sat_left {
    margin: 0;
    margin-top: 10px;
  }
  .sat_left > p {
    font-weight: 700;
    font-size: 24px;
  }
}
</style>
